import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Header, Segment, Button, List, GridColumn, Grid, GridRow } from 'semantic-ui-react';
import LabBrowser from "../components/LabBrowser";
import LabCatalog from "../components/LabCatalog";
import { useTranslation } from 'react-i18next';
import EmployeeResources from "../components/EmployeeResources";
import { useDemoContext } from "../DemoContext";
import { Container } from "@mui/material";
import Loader from "../components/Loader";
import { useFlags } from "flagsmith/react";

const Home = () => {
    const { t } = useTranslation();
    const flags = useFlags(['event_management']);
    const demoContext = useDemoContext()
    const {
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();

    return (
        <Container maxWidth='lg' sx={{marginBottom: '3.42857143rem'}}>
            {isAuthenticated ? (
                <Container className='AppContent'>
                    {demoContext.isReady ? (
                        <>
                            <LabBrowser />
                            <LabCatalog />
                            {!flags.event_management?.enabled && <EmployeeResources />}
                        </>
                    )
                        : (<Loader/>)
                    }

                </Container>
            ) : (
                <Container className='AppContent' maxWidth='lg'>
                    <Segment basic>
                       
                        <Grid relaxed>
                            <GridColumn verticalAlign="middle" computer={8} mobile={16}>
                                <GridRow>
                                    <Header as="h2" size="large" className='brandMessage'>
                                        {t('landing-banner')}
                                    </Header>
                                    <div>
                                        <Button className="secondaryCtaButton ctaButton"onClick={loginWithRedirect}>{t('getStarted')}</Button>
                                        <Button className="secondaryCtaButtonOutline ctaButton"onClick={()=>{window.open("https://www.okta.com/resources/events/")}}>{t('findEvent')}</Button>
                                    </div>
                                </GridRow>
                            </GridColumn>
                            <GridColumn computer={8} className="promoImageContainer" only="computer">
                            <img className='promoImage' src="https://cdn.demo.okta.com/images/okta-stock/lab.jpg" alt="Okta hands on labs" />
                            </GridColumn>
                        </Grid>

                    </Segment>

                    <Segment basic>
                        <div className="promoCopy">
                        {t('landing-promoCopy')}
                            </div>
                            <div className="promoCopy">
                        {t('landing-body')}
                        </div>
                    </Segment>

                    <Segment basic>
                        <Header as="h2" size="large" className='brandMessage'>
                        {t('certifiedTraining')}
                        </Header>
                        {t('certifiedTrainingCopy')}
                        <Header as="h3" className="brandMessage">
                            {t('onDemand')}
                        </Header>
                        <Grid columns={2} relaxed='very' divided>
                            <GridColumn>
                                <List>
                                <List.Header as="h4">{t('Workforce Identity Cloud')}</List.Header>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header><a className="brandColor" href="https://www.okta.com/training/introduction-to-okta-workforce-identity-cloud" target="_blank" rel="noopener noreferrer">Introduction to Workforce Identity Cloud</a></List.Header>
                                            <List.Description>You will discover the key differentiators that make Okta the leading IAM provider and learn the basics of how to use Okta. </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header><a className="brandColor" href="https://www.okta.com/training/implement-multifactor-authentication-mfa-with-okta" target="_blank" rel="noopener noreferrer">Implement Multifactor Authentication (MFA) with Okta</a></List.Header>
                                            <List.Description>Learn how to manage authentication policies and setup MFA authenticators within Okta.</List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header><a className="brandColor" href="https://www.okta.com/training/improve-your-security-posture-with-okta-identity-governance-oig" target="_blank" rel="noopener noreferrer">Improve Your Security Posture with Okta Identity Governance (OIG)</a></List.Header>
                                            <List.Description>Understand how Okta Identity Governance can play a central role in protecting, managing and auditing access to your critical resources.</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </GridColumn>
                            
                            <GridColumn>
                            <List>
                                <List.Header as="h4">{t('Customer Identity Cloud')}</List.Header>
                                <List.Item>
                                    <List.Content>
                                        <List.Header><a className="brandColor" href="https://www.okta.com/training/what-is-okta-customer-identity-cloud" target="_blank" rel="noopener noreferrer">What is Okta Customer Identity Cloud</a></List.Header>
                                        <List.Description>Learn about the benefits of Okta Customer Identity Cloud (CIC) for both business-to-consumer and business-to-business uses cases. </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header><a className="brandColor" href="https://www.okta.com/training/introduction-to-universal-login-with-auth0" target="_blank" rel="noopener noreferrer">Introduction to Universal Login</a></List.Header>
                                        <List.Description>Discover the advantages of the Universal Login flow and how to leverage social and enterprise authentication.</List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                            </GridColumn>
                        </Grid>

                        <Header as="h3" className="brandMessage">
                            {t('instructorLed')}
                        </Header>
                        <Grid columns={2} relaxed='very' divided>
                            <GridColumn>
                                <List>
                                <List.Header as="h4">{t('Workforce Identity Cloud')}</List.Header>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header><a className="brandColor" href="https://www.okta.com/training/okta-essentials" target="_blank" rel="noopener noreferrer">Okta Essentials</a></List.Header>
                                            <List.Description>This three day course sets the foundation for administrators to help ensure implementation success.</List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header><a className="brandColor" href="https://www.okta.com/training/okta-workflows-for-workforce-identity" target="_blank" rel="noopener noreferrer">Okta Workflows for Workforce Identity</a></List.Header>
                                            <List.Description>Tackle common workforce use cases with the Okta Workflows no code platform.</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </GridColumn>
                            
                            <GridColumn>
                            <List>
                                <List.Header as="h4">{t('Customer Identity Cloud')}</List.Header>
                                <List.Item>
                                    <List.Content>
                                        <List.Header><a className="brandColor" href="https://www.okta.com/training/customer-identity-cloud-implement-authentication-with-auth0" target="_blank" rel="noopener noreferrer">Implement Authentication with Auth0</a></List.Header>
                                        <List.Description>Learn about the benefits of Auth0 for both business-to-consumer and business-to-business uses cases. </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment basic><a className="brandColor" href="https://www.okta.com/services/training/#browse" target="_blank" rel="noopener noreferrer">{t('certifiedTrainingCatalogCTA')}</a></Segment>
                    <Segment basic>
                        <Header as="h2" size="large" className='brandMessage'>
                            {t('Certification')}
                        </Header>
                        <div>{t('certificationCopy')}</div>
                        <div><Button className="secondaryCtaButton ctaButton"onClick={()=>{window.open("https://www.okta.com/services/certification/")}}>{t('certificationCTA')}</Button></div>
                    </Segment>
                </Container>
            )}
        </Container>
    )
}
export default Home;