import { useTranslation } from 'react-i18next';
import './IDPComponent.css'
import { Button, Typography } from '@okta/odyssey-react-mui';
import { ExternalLinkIcon } from '@okta/odyssey-react-mui/icons';

const IDPComponent = ({ idp }) => {
    const { t } = useTranslation();

    function renderCloud(param) {
        switch (param) {
            case 'workforce-identity':
                return 'Okta';
            case 'customer-identity':
                return 'Auth0';
            case 'okta-customer-identity':
                return 'Okta Customer Identity';
            default:
                return 'Identity Cloud';
        }
    }

    return (
        <div className='component'>
            {idp ? (
                <div className='component'>
                    <Typography variant='h6'>{renderCloud(idp.type)}</Typography>
                    <Button
                        variant='secondary'
                        label={t('launch')}
                        endIcon={<ExternalLinkIcon/>}
                        onClick={() => window.open(idp.dashboard)}
                        isDisabled={idp.state !== "active"}
                    />

                    {idp.state === "error" &&
                        <span>{t('componentError')}</span>
                    }
                    {idp.state === "queued" &&
                        <span>{t('componentDeploying')}</span>
                    }

                    {idp.type === 'customer-identity' && idp.invite_link &&
                            <Button
                            variant='tertiary'
                            label={t('acceptInvite')}
                            endIcon={<ExternalLinkIcon/>}
                            onClick={() => window.open(idp.invite_link)}
                        />
                    }
                </div>
            )
                : (<div></div>)
            }
        </div>
    )

}
export default IDPComponent